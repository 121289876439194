import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './AddBusinessPartnerForm.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddBusinessPartnerForm = ({ userProfile, onAddSuccess, onAddError }) => {
  const [businessPartnerName, setBusinessPartnerName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      businessPartnerName,
      contactName,
      contactLastName,
      contactEmail,
      contactPhone,
      createdByBP: userProfile.user_metadata.BusinessPartner,
      createdByUser: userProfile.email,
      CopilotEnabled: 1,
    };

    try {
      const response = await fetch('https://fleetbooster.net/addCopilotBusinessPartner', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      toast.success('Business Partner added successfully', { autoClose: 1000 });
      onAddSuccess(data);
    } catch (error) {
      toast.error('Error adding Business Partner: ' + error.message, { autoClose: 5000 });
      onAddError(error.message);
    }
  };

  return (
    <form className="add-business-partner-form" onSubmit={handleSubmit}>
      <h2>Add Business Partner</h2>
      <div className="form-group">
        <label htmlFor="businessPartnerName">Business Partner Name</label>
        <input
          type="text"
          id="businessPartnerName"
          value={businessPartnerName}
          onChange={(e) => setBusinessPartnerName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="contactName">Contact Name</label>
        <input
          type="text"
          id="contactName"
          value={contactName}
          onChange={(e) => setContactName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="contactLastName">Contact Last Name</label>
        <input
          type="text"
          id="contactLastName"
          value={contactLastName}
          onChange={(e) => setContactLastName(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="contactEmail">Contact Email</label>
        <input
          type="email"
          id="contactEmail"
          value={contactEmail}
          onChange={(e) => setContactEmail(e.target.value)}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="contactPhone">Contact Phone</label>
        <input
          type="tel"
          id="contactPhone"
          value={contactPhone}
          onChange={(e) => setContactPhone(e.target.value)}
          required
        />
      </div>
      <button type="submit">Add Business Partner</button>
    </form>
  );
};

AddBusinessPartnerForm.propTypes = {
  userProfile: PropTypes.object.isRequired,
  onAddSuccess: PropTypes.func.isRequired,
  onAddError: PropTypes.func.isRequired,
};

export default AddBusinessPartnerForm;